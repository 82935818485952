import React from "react";

const CancellationAndRefund = () => {
  return (
    <div className=" mx-auto p-4">
      <div className="mb-6">
        <p className="text-sm text-cyan-900 font-bold ">(örnektir!)</p>
      </div>
      <div>
        <h2 className="text-sm text-cyan-900 font-bold mb-4">
          Nasıl İade Ederim ?
        </h2>
        <p className="mb-4 text-xs font-display">
          Teslimat tarihinden itibaren 7 gün içerisinde iade edebilirsiniz
          <br /><br /> İadenizi Aras Kargo (444 25 52) ile bize yollayabilirsiniz.
          <br /><br />
          *Aras Kargo Dışında Karşı Ödemeli Olarak Yapılan iadeler Kabul
          Edilmeyecektir..
          <br /><br />
          İade Adresimiz: ....
          <br /><br /><br />
          İade edeceğiniz ürünün, paketi hasar görmemiş, ürün üzerinde bulunan
          etiketi kopmamış, kullanılmamış ve kullanım hatası sonucu zarar
          görmemiş olması gerekmektedir.
          <br /><br />
          İade etmek istediğiniz ürün/ürünleri tüm aksesuarları ve orijinal
          kutusu ile faturanızın ön yüzünün altında bulunan iade formunu
          eksiksiz doldurmanız gerekmektedir.
        </p>
      </div>
    </div>
  );
};

export default CancellationAndRefund;
