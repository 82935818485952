export const categories = [
  {
    id: 1,
    name: "Oturma Grupları",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/1_v5nbsd.png",
  },
  {
    id: 2,
    name: "Yatak Odaları",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/2_ervwry.png",
  },
  {
    id: 3,
    name: "Yemek Odaları",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/3_wmbm1i.png",
  },
  {
    id: 4,
    name: "TV Üniteleri",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/4_iqwras.png",
  },
  {
    id: 5,
    name: "Köşe Takımları",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508645/5_ij9kts.png",
  },
  {
    id: 6,
    name: "Baza Yatak",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/6_kk170c.png",
  },
  {
    id: 7,
    name: "Düğün Paketleri",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508644/7_ft0vce.png",
  },
  {
    id: 8,
    name: "Sehpalar",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508645/8_wkhi3h.png",
  },
  {
    id: 9,
    name: "Dekorasyon",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508645/9_sqtmr6.png",
  },
  {
    id: 10,
    name: "Genç Odası",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508645/10_gqm4ez.png",
  },
  {
    id: 11,
    name: "Halılar",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508647/12_vx6gqi.png",
  },
  {
    id: 12,
    name: "Kampanyalar",
    img: "https://res.cloudinary.com/dd4d48hwn/image/upload/v1720508646/11_atxick.png",
  },
];
export default categories;
