import React from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function İndex() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };
  return (
    <div>
      <div class="flex md:h-32 h-72 bg-gray-100">
        <div class="flex-none md:w-24 w-4 h-14 ..."></div>
        <div class="grow h-14 flex md:flex-row mt-6">
          <div className="w-32 h-20 border rounded bg-white mr-6 pl-10 pt-5 hidden md:block">
            <HiOutlineShoppingBag
              size={35}
              strokeWidth={1}
              className="mb-2 cursor-pointer"
            />
          </div>

          <div className="w-full md:h-20 h-48 border rounded bg-white flex  md:flex-row flex-col items-center">
            <div className=" pt-5 md:hidden mb-3">
              <HiOutlineShoppingBag
                size={35}
                strokeWidth={1}
                className="mb-2 cursor-pointer"
              />
            </div>
            <div className="pl-7 font-bold text-md mb-3">
              <p>Sepetinizde Ürün Bulunmamaktadır.</p>
            </div>
            <div className="md:ml-auto md:mr-6">
              <button
                onClick={goToHome}
                className="bg-rose-500 text-white px-5 py-3 rounded hover:bg-black text-sm"
              >
                Alışverişe Devam Et
              </button>
            </div>
          </div>
        </div>
        <div class="flex-none md:w-24 w-4 h-14 ..."></div>
      </div>
    </div>
  );
}
