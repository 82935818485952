import React, { useState, useRef, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/images/nis home .png";
import { IoMdHeartEmpty } from "react-icons/io";
import { PiUserLight } from "react-icons/pi";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { SlBasket } from "react-icons/sl";
import { motion } from "framer-motion";
import products from "../../api/products"; // Assuming your products JSON file is here

export default function Search() {
  const [isHovering, setIsHovering] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState("absolute");
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [user, setUser] = useState(null); // To keep track of the authenticated user
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleHover = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const calculateDropdownPosition = () => {
    const dropdownHeight = dropdownRef.current.clientHeight;
    const navbarHeight = 24;
    const bottomOffset =
      window.innerHeight - dropdownRef.current.getBoundingClientRect().bottom;

    if (bottomOffset < dropdownHeight + navbarHeight) {
      setDropdownPosition("absolute");
    } else {
      setDropdownPosition("absolute");
    }
  };

  useEffect(() => {
    calculateDropdownPosition();
    window.addEventListener("resize", calculateDropdownPosition);
    return () => {
      window.removeEventListener("resize", calculateDropdownPosition);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  const handleProductClick = (productName) => {
    const productUrl = `/${productName}`;
    window.open(productUrl, "_blank");
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate("/"); // Redirect to home page after sign out
    });
  };

  return (
    <div className="grid grid-flow-col justify-stretch">
      <div>
        <a href="/">
          <img className="ml-6 w-[255px] py-8" src={logoImage} alt="Logo" />
        </a>
      </div>
      <div>
        <div className="flex justify-start p-2 searchBar mt-7">
          <input
            type="text"
            placeholder="Arama"
            className="w-full px-2 py-1 outline-none"
            value={searchInput}
            onChange={handleSearchInputChange}
            ref={inputRef}
          />
          <button className="bg-orange-500 text-white px-5 py-1 rounded-md ml-2">
            ARA
          </button>
        </div>

        {searchInput && filteredProducts.length > 0 && (
          <div
            ref={dropdownRef}
            className="bg-white shadow-lg mt-2 rounded-md absolute z-50"
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              width: inputRef.current ? inputRef.current.clientWidth : "100%",
            }}
          >
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="p-2 hover:bg-gray-200 cursor-pointer flex items-center"
                onClick={() => handleProductClick(product.name)}
              >
                <img
                  src={product.images.thumbnails[0]}
                  alt={product.name}
                  className="w-12 h-12 object-cover rounded mr-2"
                />
                <span>{product.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end mr-8">
        <div className="flex flex-col mt-5">
          <a href="/">
            <div className="mb-2 flex justify-center">
              <IoMdHeartEmpty size={28} strokeWidth={1} />
            </div>
            <div className="text-center font-display textSearchIcon">
              Favorilerim
            </div>
          </a>
        </div>

        {user ? (
          <div className="flex flex-col ml-10 mt-5">
            <div
              className="mb-2 flex justify-center cursor-pointer"
              onClick={() => navigate("/hesabim")}
            >
              <PiUserLight size={28} strokeWidth={1} />
            </div>
            <div
              className="text-center font-display textSearchIcon cursor-pointer"
              onClick={() => navigate("/hesabım")}
            >
              Hesabım
            </div>
            <div
              className="text-center font-display textSearchIcon cursor-pointer"
              onClick={handleSignOut}
            >
              Çıkış Yap
            </div>
          </div>
        ) : (
          <div className="flex flex-col ml-10 mt-5">
            <a href="/üye-girisi">
              <div className="mb-2 flex justify-center">
                <PiUserLight size={28} strokeWidth={1} />
              </div>
              <div className="text-center font-display textSearchIcon">
                Üye Girişi
              </div>
            </a>
            <a href="/üye-ol">
              <div className="text-center font-display textSearchIcon">
                Üye Ol
              </div>
            </a>
          </div>
        )}

        <Link to="/sepetim">
          <div
            ref={dropdownRef}
            className="flex flex-col ml-10 mt-5 relative"
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex justify-center">
              <HiOutlineShoppingBag
                size={40}
                strokeWidth={1}
                className="mb-2 cursor-pointer"
              />
            </div>
            <div className="text-center font-display textSearchIcon">
              Sepetim
            </div>
            {isHovering && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`absolute z-30 bg-white shadow-xl p-2 mt-2 -ml-16 w-56 h-40 items-center flex flex-col top-20 right-0 ${dropdownPosition}`}
              >
                <div className="border rounded-full text-white bg-orange-500 w-10 h-10 py-2 px-2 mt-2 mb-3 ">
                  <SlBasket size={21} />
                </div>
                <div className="text-xs text-gray-600">
                  Sepetinizde ürün bulunmamaktadır.
                </div>
                <div className="border w-48 h-10 bg-black text-white px-2 py-2 text-center mt-4 duration-300">
                  GENEL TOPLAM:
                </div>
              </motion.div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
