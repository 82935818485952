import React, { useRef } from "react";
import Slider from "react-slick";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

import slide1 from "../../assets/images/caraousel/title-755dd1c0-0.jpg";
import slide2 from "../../assets/images/caraousel/2.jpg";
import Responsive from "./Responsive";

export default function Index() {
  const sliderRef = useRef(null);

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div>
      <div
        className="outline-none hidden md:block "
        style={{ position: "relative" }}
      >
        <Slider ref={sliderRef} {...settings}>
          <div>
            <img className="w-full" src={slide1} alt="Slide 1" />
          </div> 
          <div>
            <img className="w-full" src={slide2} alt="Slide 2" />
          </div>
        </Slider>
        <div className="slider-navigation">
          <div className="hover:border  hover:bg-orange-500 hover:text-white ml-6 py-4 hover:duration-300">
            {" "}
            <FaAngleLeft className="slider-arrow left " onClick={prevSlide} />
          </div>
          <div className="hover:border hover:bg-orange-500 hover:text-white mr-6 py-4 hover:duration-300">
            {" "}
            <FaAngleRight className="slider-arrow right" onClick={nextSlide} />
          </div>
        </div>
      </div>
      <Responsive />
    </div>
  );
}
