import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Toaster, toast } from "react-hot-toast";

const ErrorForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    notes: '',
    issues: []
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFormData({
        ...formData,
        issues: [...formData.issues, name]
      });
    } else {
      setFormData({
        ...formData,
        issues: formData.issues.filter(issue => issue !== name)
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send form data using EmailJS
    emailjs.send('service_uwxx5lr', 'template_ko95ch2', formData, 'jsXyZrH3WZM2EFnmF')
      .then((response) => {
        toast.success('Form başarıyla gönderildi!');
      }, (error) => {
        toast.error('Form gönderilirken bir hata oluştu.');
      });
  };

  return (
    <div>
      <Toaster toastOptions={{ duration: 10000 }} />
      <form onSubmit={handleSubmit} className="p-4">
        <p className="text-md mb-4 font-semibold">
          Bu ürünün açıklamalarında, fiyat veya diğer özelliklerinde bir hata olduğunu düşünüyorsanız aşağıdaki formu doldurarak bize geri dönebilirsiniz.
        </p>

        <div className="space-y-2 mb-4">
          <div>
            <input
              type="checkbox"
              name="Ürün açıklaması yetersiz"
              id="issue1"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue1">Ürün açıklaması yetersiz</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="Açıklamada verilen bilgilerde hatalar var"
              id="issue2"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue2">Açıklamada verilen bilgilerde hatalar var</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="Ürün resmi görünmüyor veya kalitesiz"
              id="issue3"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue3">Ürün resmi görünmüyor veya kalitesiz</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="Sayfada teknik bir problem var"
              id="issue4"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue4">Sayfada teknik bir problem var</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="Fiyat bilgisi hatalı"
              id="issue5"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue5">Fiyat bilgisi hatalı</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="Bu ürün diğer sitelere göre burada daha pahalı"
              id="issue6"
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="issue6">Bu ürün diğer sitelere göre burada daha pahalı</label>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block mb-1 font-semibold">
            *E-Posta:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="notes" className="block mb-1 font-semibold">
            Notlar:
          </label>
          <textarea
            name="notes"
            id="notes"
            value={formData.notes}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            rows="4"
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
        >
          Gönder
        </button>
      </form>
    </div>
  );
};

export default ErrorForm;
