import React from 'react'
import { BsGeoAlt } from 'react-icons/bs';
import { FaPhone, FaEnvelope} from 'react-icons/fa';

export default function Address() {
  return (
    <div className="flex-1 bg-white p-4">
                    <h2 className="text-2xl font-bold border-b-2 w-20 pb-2 mb-16">İletişim</h2>
                    <div className="mb-6 font-bold text-2xl">
                        <span>NİS MOBİLYA SAN. TİC. LTD. ŞTİ.</span>
                    </div>
                    <div className="mb-4 ">
                        <BsGeoAlt className="inline mr-2" />
                        <span className='font-display text-gray-800 text-sm'>Oymaağaç Mah. 5083 Sok.No: 3/E Kocasinan/ Kayseri</span>
                    </div>
                    <div className="mb-4">
                        <FaPhone className="inline mr-2 mr-2" />
                        <span className='text-2xl font-display'>0546 469 28 66 - 0533 608 28 66</span>
                    </div>
                    <div className="mb-4">
                        <FaEnvelope className="inline mr-2" />
                        <span className='font-display text-gray-800 text-sm'>info@nismobilya.com</span>
                    </div>
                </div>
  )
}
