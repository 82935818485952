import React, { useEffect, useState } from "react";
import chesterData from "../api/products";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 md:left-6 left-3 transform -translate-y-1/2 z-10 p-2 hover:bg-orange-500 border-orange-500 px-1 py-5 duration-300"
      onClick={onClick}
    >
      <FaChevronLeft className="text-white " size={25} />
    </button>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 md:right-4 right-7 transform -translate-y-1/2 z-10 p-2 hover:bg-orange-500 border-orange-500 px-1 py-5 duration-300"
      onClick={onClick}
    >
      <FaChevronRight className="text-white" size={25} />
    </button>
  );
};

export default function Chester() {
  const [chests, setChests] = useState([]);

  useEffect(() => {
    // Filter products with the subCategory "chester"
    const filteredChests = chesterData.filter(
      (product) => product.subCategory.toLowerCase() === "chester koltuk takımı"
    );
    setChests(filteredChests);
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center md:ml-0 ml-2 mt-32">
      <div className="w-full md:max-w-[1250px]">
        <div className="font-semibold mb-3 text-xl text-left md:ml-4">CHESTER KOLTUK TAKIMI</div>
        <Slider {...settings} className="slick-slider">
          {chests.map((chest) => (
            <div key={chest.id} className="relative slick-slide">
              <a href={`/${chest.name}`}>
                <div className="border md:w-72 md:h-80 w-[170px] h-64 hover:shadow-xl md:ml-4">
                  <img src={chest.img} alt={chest.name} className="w-full md:h-56 h-40 object-cover" />
                  <div className="text-left mt-7 font-display text-gray-800 ml-4 text-sm">
                    {chest.name}
                  </div>
                  <p className="absolute border text-center bg-indigo-950 border-blue-950 shadow text-sm font-display text-white w-12 h-6 rounded-full top-6 md:right-16 right-[50px] transform translate-x-1/2 -translate-y-1/2">
                    Yeni
                  </p>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
