import React from "react";
import campaigns from "../assets/images/Campaigns/title-dacd6cd9-8.jpg";

export default function Campaigns() {
  return (
    <div className="bg-gray-100 w-full md:h-64 h-32 mb-16 pt-10 hidden md:block mt-24">
      <img  src={campaigns} />
    </div>
  );
}
