import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { auth, db } from "../../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; // Import Firestore functions
import InformationBar from "../Navbar/InformationBar";
import Search from "../Navbar/Search";
import CategoriesNavbar from "../Navbar/CategoriesNavbar";
import Footer from "../Footer";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast, { Toaster } from "react-hot-toast"; // Import React Hot Toast
import ResponsiveNavbar from "../Navbar/ResponsiveNavbar";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Giriş Başarılı!");
      navigate("/hesabım"); // Redirect to /hesabım after successful login
    } catch (err) {
      toast.error(
        "Giriş Başarısız. Lütfen e-posta adresi ve parolanızı kontrol ederek, tekrar  deneyiniz"
      );
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save user data to Firestore
      await setDoc(doc(db, "users", user.uid), {
        name,
        surname,
        email,
        phone,
        createdAt: new Date(),
      });

      toast.success(
        "Kayıt Başarılı! Lütfen e-posta adresinize gelen doğrulama koduna tıklayarak hesabınızı onaylayın."
      );
      navigate("/hesabım"); // Redirect to /hesabım after successful sign-up
    } catch (err) {
      toast.error(
        "Kayıt Başarısız! Bilgilerinizi kontrol ederek tekrar deneyiniz."
      );
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      toast.success("Giriş Başarılı!");
      navigate("/hesabım"); // Redirect to /hesabım after successful Google login
    } catch (err) {
      toast.error("Giriş Başarısız! Lütfen tekrar deneyiniz.");
    }
  };

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      toast.success("Giriş Başarılı!");
      navigate("/hesabım"); // Redirect to /hesabım after successful Facebook login
    } catch (err) {
      toast.error("Giriş Başarısız! Lütfen tekrar deneyiniz.");
    }
  };

  return (
    <div>
      <div className="hidden md:block">
        <InformationBar />
        <Search />
        <CategoriesNavbar />
      </div>{" "}
      <div className="md:hidden"><ResponsiveNavbar /></div>
      <Toaster /> {/* Add Toaster for notifications */}
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-gray-50 p-8 rounded-lg my-28 shadow-lg w-full max-w-[700px]">
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="text-center">
              <button
                className={`text-xl font-display ${
                  isLogin ? "text-blue-900 " : "text-gray-400"
                }`}
                onClick={() => setIsLogin(true)}
              >
                Giriş Yap
              </button>
              <div
                className={`w-full h-[2px] mt-2 ${
                  isLogin ? "bg-blue-900" : "bg-white"
                }`}
              />
            </div>
            <div className="text-center">
              <button
                className={`text-xl font-display ${
                  !isLogin ? "text-blue-900" : "text-gray-400"
                }`}
                onClick={() => setIsLogin(false)}
              >
                Üye Ol
              </button>
              <div
                className={`w-full h-[2px] mt-2 ${
                  !isLogin ? "bg-blue-900" : "bg-white"
                }`}
              />
            </div>
          </div>

          <div className="p-4">
            {isLogin ? (
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="E-posta"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <button className="w-full bg-blue-900 mt-4 text-white py-4 rounded mb-4">
                  Giriş Yap
                </button>
                {error && <p className="text-red-500 text-sm">{error}</p>}
              </form>
            ) : (
              <form onSubmit={handleSignUp}>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Adınız"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Soyadınız"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="E-posta"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <PhoneInput
                    country={"tr"}
                    value={phone}
                    onChange={setPhone}
                    inputStyle={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
                <button className="w-full bg-blue-900 mt-4 text-white py-4 rounded mb-4">
                  Üye Ol
                </button>
                {error && <p className="text-red-500 text-sm">{error}</p>}
              </form>
            )}

            <div className="text-center text-sm font-normal font-display mb-4">
              <a href="#" className="text-gray-800">
                {isLogin ? "Şifremi Unuttum" : ""}
              </a>
            </div>

            <div className="flex justify-between mb-4">
              <button
                onClick={handleFacebookLogin}
                className="w-full bg-white text-gray-700 py-2 rounded mr-2 flex items-center justify-center"
              >
                <FaFacebook className="text-blue-700 mr-2" />
                Facebook ile Bağlan
              </button>
              <button
                onClick={handleGoogleLogin}
                className="w-full bg-white text-gray-700 py-2 rounded ml-2 flex items-center justify-center"
              >
                <FaGoogle className="text-red-600 mr-2" />
                Google ile Bağlan
              </button>
            </div>

            {!isLogin && (
              <div className="text-left mb-4 font-display text-gray-900">
                <input type="checkbox" required className="mr-2 mt-2" />
                <span>
                  <a href="/uyelik-kosulları" className="text-blue-400">
                    Üyelik Koşullarını
                  </a>{" "}
                  ve{" "}
                  <a
                    href="kisisel-verilerin-korunması"
                    className="text-blue-400"
                  >
                    Kişisel Verilerin Korunmasını
                  </a>{" "}
                  okudum ve kabul ediyorum.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
