import React from "react";
import logoImage from "../assets/images/nis home -footer.png";
import playImage from "../assets/images/googleplay.png";
import appImage from "../assets/images/appstore.png";
import Dropdown from "./Dropdown/Dropdown";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function Footer() {

 const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/kategoriler?category=${category}`);
  };


  const dropdownOptions1 = [
    { id: 1, label: "", value: "" },
    { id: 2, label: "", value: "" },
    { id: 3, label: "", value: "" },
  ];

  const dropdownOptions2 = [
    { id: 1, label: "", value: "" },
    { id: 2, label: "", value: "" },
    { id: 3, label: "", value: "" },
  ];
  const dropdownOptions3 = [
    { id: 1, label: "", value: "" },
    { id: 2, label: "", value: "" },
    { id: 3, label: "", value: "" },
  ];
  const dropdownOptions4 = [
    { id: 1, label: "", value: "" },
    { id: 2, label: "", value: "" },
    { id: 3, label: "", value: "" },
  ];
  return (
    <div>
      <div className="w-full md:h-[500px] h-auto  footer">
        <div class="grid md:grid-cols-6 grid-cols-1 gap-2 pt-16 ">
          <div className="flex flex-col -translate-y-2">
            <img
              className="w-[255px] mb-5 flex md:items-start items-center ml-auto mr-auto md:ml-0"
              src={logoImage}
            />
            <div className="mb-2 text-white md:text-left text-center">
              Oymaağaç Mah. 5083 Sok. No:3/E Kocasinan/Kayseri
            </div>
            <div className="mb-2 text-white text-2xl font-semibold md:text-left text-center">
              +90 546 469 28 66
            </div>
            <div className="mb-2 text-white text-sm md:text-left text-center">
              info@nismobilya.com
            </div>
            <div className="mb-2 text-white font-size md:text-left text-center">
              Takipte Kal
            </div>
            <div className="social-icons flex flex-row md:items-start items-center ml-auto mr-auto md:ml-0">
              <a href="https://www.facebook.com/p/Nis-Mobilya-61556841060616/?_rdr">
                <FaFacebook size={28} className="social-icon" />
              </a>
              <a href="/">
                <FaTwitter size={28} className="social-icon" />
              </a>
              <a href="https://www.instagram.com/nis_mobilya/">
                <FaInstagram size={28} className="social-icon" />
              </a>
             <a href="https://www.tiktok.com/nis_mobilya"><FaTiktok  size={28} className="social-icon" /></a> 
              <a href="https://wa.me/+905454902866">
                <FaWhatsapp size={28} className="social-icon" />
              </a>
            </div>
          </div>
          <div className="flex flex-col ml-6 hidden md:block">
  <div className="text-sm font-size text-white font-display">
    KURUMSAL
  </div>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Biz Kimiz
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Hikayemiz
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Showroomlar
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Kayseri Mobilya
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Basında Biz
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Blog
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Bize Ulaşın
  </a>
</div>

          <div className="flex flex-col ml-3 hidden md:block">
            <div href="/kategori" className="text-sm font-size text-white font-display cursor-pointer" onClick={() => handleCategoryClick("")} >
              KATEGORİ
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer" onClick={() => handleCategoryClick("Oturma Grupları")}>
              Oturma Grupları
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer"onClick={() => handleCategoryClick("Yatak Odası")} >
              Yatak Odası
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer" onClick={() => handleCategoryClick("Yemek Odası")}>
              Yemek Odası
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer"onClick={() => handleCategoryClick("Köşe Takımları")} >
              Köşe Takımları
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer" onClick={() => handleCategoryClick("TV Ünitesi")}>
              TV Ünitesi
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer" onClick={() => handleCategoryClick("Genç Odası")}>
              Genç Odası
            </div>
            <div className="mb-2 text-[13px] footer-text-color font-display cursor-pointer" onClick={() => handleCategoryClick("Halılar")}>
              Halılar
            </div>
          </div>
          <div className="flex flex-col ml-3 hidden md:block">
  <div className="text-sm font-size text-white font-display">
    YARDIM & DESTEK
  </div>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Sıkça Sorulan Sorular
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Müşteri Hizmetleri
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Teslimat ve Kurulum
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    KVKK Aydınlatma Metni
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Gizlilik & Güvenlik
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    Mesafeli Satış Sözleşmesi
  </a><br/>
  <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
    İptal ve İade Şartları
  </a>
</div>

          <div className="flex flex-col ml-3 hidden md:block">
            <div className="text-sm font-size text-white font-display">
              ÜYELİK
            </div>
            <a href="/üye-girisi" className="mb-2 text-[13px] footer-text-color font-display">
              Giriş Yap
            </a><br/>
            <a href="/üye-girisi" className="mb-2 text-[13px] footer-text-color font-display">
              Hemen Üye Ol
            </a><br/>
            <a href="/üye-girisi" className="mb-2 text-[13px] footer-text-color font-display">
              Favorilerim
            </a><br/>
            <a href="/sepetim" className="mb-2 text-[13px] footer-text-color font-display">
              Sepetim
            </a><br/>
            <a href="/"className="mb-2 text-[13px] footer-text-color font-display">
              Sipariş İşlemleri
            </a><br/>
            <a href="/" className="mb-2 text-[13px] footer-text-color font-display">
              İptal İade İşlemleri
            </a><br/>
            <a href="/iletisim" className="mb-2 text-[13px] footer-text-color font-display">
              İletişim
            </a>
          </div>{" "}
          <div className="flex flex-col hidden md:block">
            <div className="text-sm font-size text-white font-display">
              MOBİL UYGULAMALAR
            </div>
            <img className="mb-6 w-40 ml-2 mt-4" src={playImage} />
            <img className="w-40 ml-2" src={appImage} />
          </div>
        </div>
        <Dropdown options={dropdownOptions1} initialText="KURUMSAL" />
        <Dropdown options={dropdownOptions2} initialText="KATEGORİ" />
        <Dropdown options={dropdownOptions3} initialText="YARDIM & DESTEK" />
        <Dropdown options={dropdownOptions4} initialText="ÜYELİK" />
        <div className="w-full h-[1px] bg-gray-700 divide-y divide-double mt-20"></div>
        <div className="text-left text-[13px] footer-text-color font-display md:mt-8 mt-4 md:mb-0 pb-4">
          nismobilya.com- Tüm Hakları Saklıdır. © 2024
        </div>
      </div>
    </div>
  );
}
