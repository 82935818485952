import React from 'react'
import InformationBar from '../components/Navbar/InformationBar'
import Search from '../components/Navbar/Search'
import CategoriesNavbar from '../components/Navbar/CategoriesNavbar'
import ResponsiveNavbar from '../components/Navbar/ResponsiveNavbar'
import Index from '../components/HeroSection/Index'
import Categroies from '../components/Categories/Categroies'
import Chester from '../components/Chester'
import ModernCornerSofa from '../components/ModernCornerSofa'
import Campaigns from '../components/Campaigns'
import DiningRoom from '../components/DiningRoom'
import OurPicksForYou from '../components/OurPicksForYou'
import ENewsletter from '../components/ENewsletter'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <div >
        <InformationBar/>
       <div className='hidden md:block'><Search/></div> 
       <div className='hidden md:block'> <CategoriesNavbar/></div>
       <div className='md:hidden'><ResponsiveNavbar/></div>
        <Index/>
        <Categroies/>
        <Chester/>
        <ModernCornerSofa/>
        <Campaigns/>
        <DiningRoom/>
        <OurPicksForYou/>
        <ENewsletter/>
        <Footer/>
    </div>
  )
}
