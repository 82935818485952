import React, { useEffect, useState } from "react";
import chesterData from "../api/products";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // İkonları import ettik



const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="absolute top-1/2 md:left-6 left-3 transform -translate-y-1/2 z-10 p-2 hover:bg-orange-500 border-orange-500 px-1 py-5 duration-300"
        onClick={onClick}
      >
        <FaChevronLeft className="text-white " size={25} />
      </button>
    );
  };
  
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="absolute top-1/2 md:right-4 right-7 transform -translate-y-1/2 z-10 p-2 hover:bg-orange-500 border-orange-500 px-1 py-5 duration-300"
        onClick={onClick}
      >
        <FaChevronRight className="text-white" size={25} />
      </button>
    );
  };

  
export default function DiningRoom() {

  const [chests, setChests] = useState([]);

  useEffect(() => {
    // Filter products with the subCategory "chester"
    const filteredChests = chesterData.filter(
      (product) => product.subCategory.toLowerCase() === "köşe koltuk takımı"
    );
    setChests(filteredChests);
  }, []);
    // Slider ayarları
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  
  return (
    <div>
         <div className="flex justify-center md:px-2 md:ml-0 ml-2 mt-20 mb-5">
      <div className="w-full max-w-[1250px]">
        <Slider {...settings} className="slick-slider">
          {chests.map((chest, index) => (
            <div key={chest.id || index} className="relative slick-slide">
              <a href={`/${chest.name}`}>
              <div className="border md:w-96 md:h-96 w-[170px] h-64 md:ml-4 hover:shadow-xl">
                <img src={chest.img} alt={chest.name} className="w-full md:h-72 h-40 object-cover" />
                <div className="text-left mt-7 font-display text-gray-800 ml-4 text-sm">
                  {chest.name}
                </div>
                <p className="absolute border text-center bg-indigo-950 border-blue-950 shadow text-sm font-display text-white w-12 h-6 rounded-full top-6 right-16 transform translate-x-1/2 -translate-y-1/2">
                  Yeni
                </p>
              </div>
             </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
    </div>
  )
}
