import React, { useEffect, useState } from "react";
import categoriesData from "../../api/categories";

export default function Categories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(categoriesData);
  }, []);

  const handleCategoryClick = (category, subCategory) => {
    const url = `/kategoriler?category=${category}${subCategory ? `&subCategory=${subCategory}` : ''}`;
    window.open(url, '_blank');  // Yeni sekmede açmak için
  };

  return (
    <div className="flex flex-col items-center mt-12 px-4 md:px-10 lg:px-20">
      {/* Başlık */}
      <div className="text-center">
        <div className="text-lg md:text-3xl lg:text-4xl font-display">
          Evinize Şıklık Katacak
          <span className="font-bold ml-2">Mobilyalar</span>
        </div>
      </div>

      {/* Kategoriler */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 mt-10 w-full">
        {categories.map((category, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-white border shadow-md hover:bg-gray-100 transition-all duration-300 p-4 rounded-md cursor-pointer"
            onClick={() => handleCategoryClick(category.name, category.subCategory)}
          >
            <img
              className="w-20 h-20 md:w-28 md:h-28 lg:w-32 lg:h-32 object-contain"
              src={category.img}
              alt={category.name}
            />
            <div className="text-center font-display font-semibold text-sm md:text-base mt-3">
              {category.name}
            </div>
            {/* SubCategory gösterimi, eğer varsa */}
            {category.subCategory && (
              <div className="text-sm text-gray-500 mt-1">{category.subCategory}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
