import React from "react";
import myImage from "../../assets/images/inegol-logo4.webp";

export default function InformationBar() {
  return (
    <div className="bg-gray-100 w-full h-9 hidden md:block">
      <div className="flex justify-between">
        <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display">
          | Bilgi ve Destek Hattı : 0546 469 2866 - 0533 456 2866
        </div>
        <div></div>
        <div className="flex flex-row">
          <a href="/">
            <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display ">
              Sipariş Takip
            </div>
          </a>
          <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display ml-6">
            -
          </div>
          <a href="/">
            <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display ml-6">
              Hakkımızda
            </div>
          </a>
          <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display ml-6">
            -
          </div>
          <a href="/iletisim">
            <div className="mt-2.5 ml-3.5 text-xs text-gray-900 font-display ml-6 mr-4">
              İletişim
            </div>
          </a>
          <img className="mt-1 hover:-translate-y-3 ml-2" src={myImage} />
        </div>
      </div>
    </div>
  );
}
