import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ErrorForm from "./ErrorForm";
import DeliveryAndInstallation from "./DeliveryAndInstallation";
import CancellationAndRefund from "./CancellationAndRefund";

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState("productDetails");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  const goToLogin = () => {
    navigate("/üye-girisi");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "productDetails":
        return (
          <div className="flex flex-col ml-7 mb-4">
            <div className="font-bold text-cyan-900 mb-6">ÜRÜN AÇIKLAMA</div>
            <div className="flex flex-row text-sm">
              <p className="font-semibold">Takım:</p>2 Adet Üçlü Koltuk ve 1
              Adet Tekli Koltuktan oluşmaktadır.
            </div>
            <div className="text-sm">
              Fırınlanmış gürgen ağacı ve kayınağacından üretilmiştir.Alt kasa
              kısmı metal profildir
            </div>
            <div className="text-sm">
              Oturumu orta sertliktedir. Ayaklar metal malzemedir.
            </div>
            <div className="text-sm">
              Yatak olabilen sistem mekanizması mevcuttur.
            </div>
            <div className="text-sm">
              Yumuşak dokulu ithal kumaştan üretilmiştir.
            </div>
            <div className="text-sm">
              Farklı renk seçenekleri ile üretilmektedir.
            </div>
            <div className="text-sm">
              Tekli modül olarakta satın alabilirsiniz.
            </div>
            <div className="text-sm font-semibold">
              KOLTUK TAKIMINA EKSTRA MODÜL DAHİL ETMEK İÇİN LÜTFEN MÜŞTERİ
              HİZMETLERİMİZLE İLETİŞİME GEÇİNİZ.
            </div>
            <div className="flex flex-row text-sm">
              <p className="font-semibold">Not:</p>Mobilyalarınızı nemli bezle
              silerek temizleyebilirsiniz. Direkt güneş ışığından koruyunuz.
              Sıcak yüzeylerin ve suyun uzun süreli yüzeye temasından kaçınınız.
            </div>
            <div className="text-sm font-semibold">
              UYARI: Ürünü kargodan teslim almadan önce kontrol ediniz, hasarlı
              ise lütfen ürünü teslim almayınız ve müşteri hizmetlerimizi
              arayınız.
            </div>
            <div className="font-bold text-cyan-900 mb-4 mt-8">TESLİMAT</div>
            <div className="text-sm font-semibold">
              - Teslimatlar tüm Türkiye'ye yapılmaktadır.
            </div>
            <div className="text-sm font-semibold">
              - Randevulu olarak görüşmeler sağlanmaktadır.
            </div>
            <div className="text-sm font-semibold">
              - Teslimat süresi 29 iş günüdür.
            </div>
            <div className="text-sm font-semibold">
              - Kurulum gerektiren ürünler Nisan Mobilya tarafından belitilen
              bölgelere yapılmaktadır.
            </div>
          </div>
        );
      case "reviews":
        return (
          <div className="p-4 flex flex-col justify-center items-center">
            <div className="mb-8 font-bold text-xl">
              Senden Duymak İstiyoruz
            </div>
            <div className="mb-8 text-xs font-display">
              Bize bu ürün hakkında ne düşündüğünüzü söyleyin. Yaptığımız işte
              daha iyi olmamıza ve nihayetinde size daha iyi ürünler sunmamıza
              yardımcı olur.
            </div>
            {isLoggedIn ? (
              <div className="border px-2 py-2 bg-black text-white font-display flex items-center">
                <FaExclamationTriangle className="mr-2" />
                Sitemizde çalışmamız devam ettiği içim yorum yapılmamaktadır. Lütfen daha sonra tekrar deneyiniz.
              </div>
            ) : (
              <div className="border px-2 py-2 bg-black text-white font-display">
                <button onClick={goToLogin}>Yorum Yap</button>
              </div>
            )}
          </div>
        );
      case "suggestions":
        return <div className="p-4"><ErrorForm /></div>;
      case "deliverySetup":
        return <div className="p-4"><DeliveryAndInstallation /></div>;
      case "returnCancellation":
        return <div className="p-4"><CancellationAndRefund /></div>;
      default:
        return null;
    }
  };

  return (
    <div className="border border-gray-300 mb-6 container mx-4 h-auto">
      <div className="border border-gray-300 w-full">
        <button
          className={`py-3 px-16 text-xs font-medium border ${
            activeTab === "productDetails" ? "bg-gray-200 border-gray-200" : ""
          }`}
          onClick={() => setActiveTab("productDetails")}
        >
          ÜRÜN ÖZELLİKLERİ
        </button>
        <button
          className={`py-3 px-16 text-xs font-medium border ${
            activeTab === "reviews" ? "bg-gray-200 border-gray-200" : ""
          }`}
          onClick={() => setActiveTab("reviews")}
        >
          YORUMLAR(0)
        </button>
        <button
          className={`py-3 px-16 text-xs font-medium border ${
            activeTab === "suggestions" ? "bg-gray-200 border-gray-200" : ""
          }`}
          onClick={() => setActiveTab("suggestions")}
        >
          ÜRÜN ÖNERİLERİ
        </button>
        <button
          className={`py-3 px-16 text-xs font-medium border ${
            activeTab === "deliverySetup" ? "bg-gray-200 border-gray-200" : ""
          }`}
          onClick={() => setActiveTab("deliverySetup")}
        >
          TESLİMAT VE KURULUM
        </button>
        <button
          className={`py-3 px-16 text-xs font-medium border ${
            activeTab === "returnCancellation" ? "bg-gray-200 border-gray-200" : ""
          }`}
          onClick={() => setActiveTab("returnCancellation")}
        >
          İADE VE İPTAL
        </button>
      </div>
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default TabComponent;
