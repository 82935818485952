import React from "react";
import { useParams } from "react-router-dom";
import products from "../api/products";
import SiteMap from "../components/ProductDetail/SiteMap";
import TabDetail from "../components/ProductDetail/TabDetail";
import ProductPhoto from "../components/ProductDetail/ProductPhoto";
import InformationBar from "../components/Navbar/InformationBar";
import Search from "../components/Navbar/Search";
import CategoriesNavbar from "../components/Navbar/CategoriesNavbar";
import ResponsiveNavbar from "../components/Navbar/ResponsiveNavbar";
import Footer from "../components/Footer";

export default function ProductDetail() {
  const { name } = useParams(); // URL'den ürün ID'sini almak
  const product = products.find((p) => p.name === name); // Ürünü bulmak

  if (!product) return <div>Ürün bulunamadı.</div>;

  return (
    <div>
      <InformationBar />
      <div className="hidden md:block">
        <Search />
      </div>
      <div className="hidden md:block">
        {" "}
        <CategoriesNavbar />
      </div>
      <div className="md:hidden">
        <ResponsiveNavbar />
      </div>
      <SiteMap
        category={product.category}
        subCategory={product.subCategory}
        name={product.name}
      />
      <ProductPhoto 
      images = {product.images.thumbnails}
      product={product}/>
      <TabDetail/>
      <Footer />
    </div>
  );
}
