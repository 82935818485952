import React, { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link for routing

const ProductCard = ({ product }) => {
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  const handleHeartClick = () => {
    setIsHeartClicked(true);
    window.open("/üye-girisi", "_blank");
  };

  return (
    <div className="border h-96 shadow-lg relative">
      {/* Wrap the image with Link for navigation based on product name */}
      <Link to={`/${encodeURIComponent(product.name)}`}>
        <img
          src={product.img}
          alt={product.name}
          className="w-full h-72 object-cover mb-4"
        />
      </Link>
      {/* Wrap the product name with Link for navigation */}
      <Link to={`/products/${encodeURIComponent(product.name)}`}>
        <h3 className="text-left font-display text-gray-900 ml-4">
          {product.name}
        </h3>
      </Link>
      {isHeartClicked ? (
        <FaHeart
          onClick={handleHeartClick}
          className="absolute bottom-2 right-2 cursor-pointer text-red-500"
          size={24}
        />
      ) : (
        <FaRegHeart
          onClick={handleHeartClick}
          className="absolute bottom-2 right-2 cursor-pointer text-gray-400"
          size={24}
        />
      )}
    </div>
  );
};

export default ProductCard;
