import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Toaster, toast } from "react-hot-toast";

export default function ENewsletter() {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Emailjs ile gönderim
    emailjs
      .send(
        'service_uwxx5lr', // service ID
        'template_ko95ch2', // template ID (enewsletter için oluşturduğunuz şablon)
        { email }, // form data
        'jsXyZrH3WZM2EFnmF' // public key
      )
      .then(
        (response) => {
          console.log("Success:", response);
          toast.success(
            `E-bültene başarıyla kaydoldunuz! En kısa sürede duyurular size ulaşacak.`
          );
          setEmail(""); // Formu temizle
        },
        (error) => {
          console.error("Error:", error);
          toast.error("Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyiniz.");
        }
      );
  };

  return (
    <div>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="flex flex-col items-center">
        <div className="w-full h-[1px] bg-gray-700 divide-y divide-double"></div>
        <div className="font-bold text-2xl mt-8">E-BÜLTEN KAYIT</div>
        <div className="font-display text-gray-500 mt-16 text-sm text-center">
          Hemen E-posta listemize kayıt ol, en güncel kampanyalar,
          <br /> yenilikler ve duyuruları ilk öğrenen sen ol.
        </div>
        <form onSubmit={handleSubmit} className="border mt-8 mb-20 rounded-r">
          <input
            type="email"
            placeholder="E-posta adresinizi yazın..."
            value={email}
            onChange={handleChange}
            className="md:w-[350px] w-[250px] h-10 px-2 py-1 outline-none"
            required
          />
          <button
            type="submit"
            className="bg-emerald-500 font-display text-white text-sm px-3 py-1 rounded ml-2 h-12"
          >
            GÖNDER
          </button>
        </form>
        <div className="w-full h-[3px] bg-emerald-500 divide-y divide-double"></div>
      </div>
    </div>
  );
}
