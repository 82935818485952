import React from "react";
import { useNavigate } from "react-router-dom";

export default function CategoriesNavbar() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/kategoriler?category=${category}`);
  };

  return (
    <div className="flex">
      <div className="flex-none w-8 h-14 border"></div>
      <div className="grow h-14 w-full grid grid-cols-8 gap-0">
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("")}
        >
          KATEGORİ
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Oturma Grupları")}
        >
          Oturma Grupları
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Yemek Odası")}
        >
          Yemek Odası
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Yatak Odası")}
        >
          Yatak Odası
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Köşe Koltuk")}
        >
          Köşe Koltuk
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Yaşam Ünitesi")}
        >
          Yaşam Ünitesi
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Genç ve Çocuk")}
        >
          Genç ve Çocuk
        </div>
        <div
          className="border w-full h-full text-center pt-4 font-display hover:text-orange-500 cursor-pointer"
          onClick={() => handleCategoryClick("Halılar")}
        >
          Halılar
        </div>
      </div>
      <div className="flex-none w-8 h-14 border"></div>
    </div>
  );
}
