import React, { useState, useEffect } from "react";
import { products } from "../api/products";
import { useLocation } from "react-router-dom"; // To read query parameters
import ProductCard from "./ProductCard";
import InformationBar from "./Navbar/InformationBar";
import Search from "./Navbar/Search";
import CategoriesNavbar from "./Navbar/CategoriesNavbar";
import ResponsiveNavbar from "./Navbar/ResponsiveNavbar";
import Footer from "./Footer";
import { FaBoxOpen } from "react-icons/fa"; // Importing an icon from react-icons

const ProductList = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortOption, setSortOption] = useState("");
  const [showInStock, setShowInStock] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const location = useLocation();

  // Extract category from query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    setSelectedCategory(category || null);
  }, [location]);

  const handleSortChange = (event) => setSortOption(event.target.value);
  const handleInStockChange = () => setShowInStock(!showInStock);

  // Filter and sort products
  const filteredProducts = products
    .filter((product) =>
      selectedCategory ? product.category === selectedCategory : true
    )
    .filter((product) => (showInStock ? product.stock > 0 : true)) // Filter in-stock products
    .sort((a, b) => {
      if (sortOption === "name-asc") return a.name.localeCompare(b.name);
      if (sortOption === "name-desc") return b.name.localeCompare(a.name);
      return 0;
    });

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <div className="hidden md:block">
        <InformationBar />
        <Search />
        <CategoriesNavbar />
        <ResponsiveNavbar />
      </div>
<div className="md:hidden">
        <ResponsiveNavbar />
      </div>
      <div className="container mx-auto p-4">
        <nav className="text-sm font-display mb-4">
          <a href="/">Ana Sayfa</a>  >  <a href="/kategoriler">Kategoriler</a> 
          {selectedCategory && ` >  ${selectedCategory}`}
        </nav>
        <div className="border-b border-gray-200 mb-4"></div>

        {/* Sorting and Stock Filter */}
        <div className="flex justify-between mb-4 text-sm font-display">
          <div className="flex items-center">
            <select
              className="p-2"
              value={sortOption}
              onChange={handleSortChange}
            >
              <option value="">Sıralama Seçiniz</option>
              <option value="name-asc">A'dan Z'ye</option>
              <option value="name-desc">Z'den A'ya</option>
            </select>
            <label className="inline-flex items-center ml-4">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={showInStock}
                onChange={handleInStockChange}
              />
              <span className="ml-2">Stoktakiler</span>
            </label>
          </div>
        </div>

        {/* Product Grid */}
        {filteredProducts.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-64">
            <FaBoxOpen className="text-6xl text-gray-400 mb-4" />
            <p className="text-lg text-gray-500 font-display">Ürün bulunamadı</p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {paginatedProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>

            {/* Pagination */}
            <div className="mt-4">
              {Array.from(
                { length: Math.ceil(filteredProducts.length / itemsPerPage) },
                (_, i) => (
                  <button
                    key={i}
                    onClick={() => setCurrentPage(i + 1)}
                    className={`mx-1 px-3 py-1 ${
                      currentPage === i + 1 ? "bg-gray-300" : ""
                    }`}
                  >
                    {i + 1}
                  </button>
                )
              )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProductList;
