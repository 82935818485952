import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Dropdown = ({ options, initialText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Dropdown'u kapat
  };

  const shouldShowUpArrow = selectedOption && selectedOption.label.length > initialText.length;

  return (
    <div className="dropdown block md:hidden ">
      <button className="dropdown-toggle mb-6" onClick={toggleDropdown}>
        <span className="dropdown-text">{selectedOption ? selectedOption.label : initialText}</span>
        {isOpen ? (shouldShowUpArrow ? <FaChevronUp /> : <FaChevronDown />) : <FaChevronDown />}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li key={option.id} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
