import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Toaster, toast } from "react-hot-toast";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const senderName = formData.name; // get the sender's name from the form

    emailjs
      .send(
        'service_uwxx5lr', // service ID
        'template_ko95ch2', // template ID
        formData, // form data
        'jsXyZrH3WZM2EFnmF' // public key
      )
      .then(
        (response) => {
          console.log('Success:', response);
          toast.success(
            `Sayın ${senderName}, mesajınız başarıyla iletildi. Kısa süre içerisinde yetkililerimiz sizinle iletişime geçecektir.`
          );
          setStatus("Mesajınız gönderildi!");
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.error('Error:', error);
          toast.error(
            `Sayın ${senderName}, mesajınız iletilemedi! Kontrol ederek tekrar deneyiniz.`
          );
          setStatus("Bir hata oluştu. Lütfen tekrar deneyin.");
        }
      );
  };

  return (
    <div className="flex-1 bg-white p-4">
      <Toaster toastOptions={{ duration: 10000 }} />

      <h2 className="text-2xl font-bold border-b-2 w-48 pb-2 mb-4">
        İletişim Formu
      </h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-8 mt-16">
          <label
            className="block text-sm font-medium mb-1 font-semibold"
            htmlFor="name"
          >
            Ad Soyad
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 shadow focus:outline-none"
            placeholder="Adınız *"
            required
          />
        </div>
        <div className="mb-8">
          <label
            className="block text-sm font-medium mb-1 font-semibold"
            htmlFor="phone"
          >
            Telefon
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 shadow focus:outline-none"
            placeholder="Telefon Numaranız *"
            required
          />
        </div>
        <div className="mb-8">
          <label
            className="block text-sm font-medium mb-1 font-semibold"
            htmlFor="email"
          >
            E-posta
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 shadow focus:outline-none"
            placeholder="E-Mail *"
            required
          />
        </div>
        <div className="mb-8">
          <label
            className="block text-sm font-medium mb-1 font-semibold"
            htmlFor="message"
          >
            Mesaj
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 shadow focus:outline-none"
            rows="4"
            placeholder="Mesajınız..."
            required
          />
        </div>
        <button
          type="submit"
          className="w-28 h-16 bg-black text-white shadow"
        >
          GÖNDER
        </button>
        {status && <p className="mt-4 text-sm font-semibold">{status}</p>}
      </form>
    </div>
  );
}
