import React from 'react'
import Slider from "react-slick";
import slide3 from "../../assets/images/caraousel/slide3.jpg";
import slide4 from "../../assets/images/caraousel/slide4.jpg";
import slide5 from "../../assets/images/caraousel/slide5.jpg";

export default function Responsive() {
    

  const settings2 = {
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoPlay :true,
  };
  return (
    <div
        className="outline-none md:hidden mx-2"
        style={{ position: "relative" }}
      >
        <Slider  {...settings2}>
          <div>
            <img src={slide3} />
          </div>
          <div>
            <img src={slide4} />
          </div>
          <div>
            <img src={slide5} />
          </div>
        </Slider>
      </div>
  )
}
