import React, { useEffect, useState } from "react";
import chesterData from "../api/products";

export default function OurPicksForYou() {
  const [chests, setChests] = useState([]);

  useEffect(() => {
    setChests(chesterData);
  }, []);

  const generateLink = (name) => {
    return `/${name}`;
  };

  return (
    <div className="flex justify-center px-2 pb-8 mt-32 bg-gray-100">
      <div className="w-full max-w-[1250px]">
        <div className="font-semibold mb-3 text-xl text-left md:ml-4 mt-3">
          SİZİN İÇİN SEÇTİKLERİMİZ
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {chests.map((chest, index) => (
            <a
              key={chest.id || index}
              href={generateLink(chest.name)}
              className="relative"
            >
              <div className="border bg-white md:w-72 md:h-80 w-[170px] h-64 shadow hover:shadow-xl shadow:translate-x-1">
                <img
                  src={chest.img}
                  alt={chest.name}
                  className="w-full md:h-56 h-40 object-cover"
                />
                <div className="text-left mt-7 font-display text-gray-800 ml-4 text-sm">
                  {chest.name}
                </div>
                <p className="absolute border text-center bg-indigo-950 border-blue-950 shadow text-sm font-display text-white w-12 h-6 rounded-full top-6 md:right-16 right-[50px] transform translate-x-1/2 -translate-y-1/2">
                  Yeni
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
