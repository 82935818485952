import React, { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { PiBasketThin } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { motion } from "framer-motion"; // Import framer-motion
import { useNavigate } from "react-router-dom";
import products from "../../api/products"; // Assuming your products JSON file is here
import logoImage from "../../assets/images/nis home .png";



export default function ResponsiveNavbar() {

  const handleCategoryClick = (category) => {
    navigate(`/kategoriler?category=${category}`);
  };


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Handle search input change and filter products
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    // Filter products based on input value
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  // Handle product click and navigate to product page based on product name
  const handleProductClick = (productName) => {
    const productUrl = `/${productName}`;
    navigate(productUrl);
  };

  return (
    <div>
      <header className="bg-white text-gray-800 p-4 md:hidden">
        <nav className="flex items-center justify-between">
          {/* Hamburger Menu */}
          <div className="relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-2xl text-black"
            >
              <RxHamburgerMenu />
            </button>

            {/* Animated Menu */}
            {isMenuOpen && (
              <motion.div
                initial={{ x: "-100%" }} // Starting point
                animate={{ x: 0 }} // Animate to full view
                exit={{ x: "-100%" }} // Exit animation
                transition={{ duration: 0.5 }} // Duration of the animation
                className="fixed top-0 left-0 z-30 bg-white text-black w-full h-full"
              >
                <button
                  onClick={() => setIsMenuOpen(false)}
                  className="text-6xl absolute top-4 right-4 text-gray-500"
                >
                  <IoMdClose />
                </button>

                <ul className="mt-16 px-4 font-display">
                  {/* Categories */}
                  <li>
                    <div  className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("")}>
                      KATEGORİ
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Oturma Grupları")}>
                      Oturma Grupları
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div  className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Yemek Odası")}>
                      Yemek Odası
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div  className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Yatak Odası")}>
                      Yatak Odası
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Köşe Takımları")}>
                      Köşe Takımları
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div  className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Yaşam Ünitesi")}>
                      Yaşam Ünitesi
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Genç ve Çocuk")}>
                      Genç ve Çocuk
                    </div>
                  </li>
                  <hr className="border-gray-300" />
                  <li>
                    <div  className="block py-2 text-black cursor-pointer" onClick={() => handleCategoryClick("Halılar")}>
                      Halılar
                    </div>
                  </li>
                </ul>
              </motion.div>
            )}
          </div>

          {/* Logo */}
          <div className="flex-grow flex justify-center items-center ml-10">
            <img className="w-64" src={logoImage} alt="Logo" />
          </div>

          {/* User and Basket Icons */}
          <div className="flex space-x-3 items-center">
            <a href="/üye-girisi">
              <CiUser size={44} color="black" className="p-2 rounded-full" />
            </a>
            <a href="/sepetim">
              <PiBasketThin size={44} color="black" className="p-2 rounded-full" />
            </a>
          </div>
        </nav>

        {/* Search Area */}
        <div className="mt-4 flex justify-center">
          <div className="relative w-full max-w-md">
            <input
              id="search"
              type="text"
              placeholder="Arama"
              className="bg-gray-100 text-gray-800 rounded-l p-2 pr-10 outline-none w-full h-12"
              value={searchInput}
              onChange={handleSearchInputChange}
              ref={inputRef}
            />
            <FaSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700" />

            {/* Dropdown for filtered products */}
            {searchInput && filteredProducts.length > 0 && (
              <div
                ref={dropdownRef}
                className="bg-white shadow-lg mt-2 rounded-md absolute z-50"
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  width: inputRef.current
                    ? inputRef.current.clientWidth
                    : "100%", // Match dropdown width to input width
                }}
              >
                {filteredProducts.map((product) => (
                  <div
                    key={product.id}
                    className="p-2 hover:bg-gray-200 cursor-pointer flex items-center"
                    onClick={() => handleProductClick(product.name)}
                  >
                    <img
                      src={product.images.thumbnails[0]}
                      alt={product.name}
                      className="w-12 h-12 object-cover rounded mr-2"
                    />
                    <span>{product.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}
