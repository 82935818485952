import React from "react";
import InformationBar from "../components/Navbar/InformationBar";
import Search from "../components/Navbar/Search";
import CategoriesNavbar from "../components/Navbar/CategoriesNavbar";
import İndex from "../components/Basket/İndex";
import ENewsletter from "../components/ENewsletter";
import Footer from "../components/Footer";

export default function Basket() {
  return (
    <div>
      <div className="hidden md:block">
        <InformationBar />
      </div>
      <div className="hidden md:block">
        <Search />
      </div>
      <div className="hidden md:block">
        <CategoriesNavbar />
      </div>
      <İndex />
      <div className="bg-gray-100 hidden md:block">
        <ENewsletter />
      </div>
        <Footer />
    </div>
  );
}
