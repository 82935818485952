import React, { useState } from "react";
import ProductDetails from "./ProductDetails";
import { FaTimes } from "react-icons/fa";

export default function ProductPage({ images, product }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = () => {
    setIsFullscreen(true);
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const handleMouseMove = (e) => {
    if (isZoomed) {
      const rect = e.target.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      setMousePosition({ x, y });
    }
  };

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 p-4">
        <div
          className="relative w-full h-64 overflow-hidden"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onClick={handleImageClick}
          style={{
            paddingTop: "100%",
            backgroundImage: `url(${selectedImage})`,
            backgroundSize: isZoomed ? "150%" : "cover",
            backgroundPosition: isZoomed ? `${mousePosition.x}% ${mousePosition.y}%` : "center",
            backgroundRepeat: "no-repeat",
            transition: "background-size 0.3s ease",
            cursor: "zoom-in",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            className="absolute inset-0 w-full h-full object-cover opacity-0"
          />
        </div>
        <div className="flex mt-2 space-x-2 overflow-x-auto">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`w-16 h-16 cursor-pointer ${selectedImage === image ? "border-2 border-blue-500" : ""}`}
              onClick={() => handleThumbnailClick(image)}
            />
          ))}
        </div>

        {isFullscreen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative">
              <FaTimes
                className="absolute top-2 right-2 text-white text-2xl cursor-pointer"
                onClick={handleCloseFullscreen}
              />
              <img
                src={selectedImage}
                alt="Fullscreen"
                className="w-auto max-w-screen-lg max-h-screen"
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-1/2 p-4">
        <ProductDetails product={product}/>
      </div>
    </div>
  );
}
