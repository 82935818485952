import React from "react";

const DeliveryAndInstallation = () => {
  return (
    <div className=" mx-auto p-4">
      <div className="mb-6">
        <p className="text-sm text-cyan-900 font-bold ">
          95.000 TL VE ÜZERİ ALIŞVERİŞLERİNİZDE ÜCRETSİZ NAKLİYE VE KURULUM
          HİZMETİ NİS MOBİLYADA !!!
        </p>
      </div>
      <div>
        <h2 className="text-sm text-cyan-900 font-bold mb-4">KARGO TESLİM ALMA İŞLEMİ</h2>
        <p className="mb-4 text-xs font-display">
          Kargonuz elinize ulaştığında doğrudan kargo görevlisinin ibraz
          edeceği, ürünü teslim aldığınızı gösteren belgeye imza atmadan önce
          ürününüzün paketini açarak,
          <br /> ürününüzde herhangi bir hasar olup olmadığına, ürünün eksiksiz
          bir şekilde teslim edildiğinden emin olun.
          <br />
          Eğer ürün eksiksiz ve hasarsız bir şekilde ulaştırıldıysa, kargo
          görevlisinin uzattığı teslim belgesini imzalayabilirsiniz. Eğer üründe
          bir hasar ya da eksik varsa, kargo görevlisinden “Hasar Tespit
          Tutanağı” hazırlamasını isteyin ve ürünü teslim almayın.
          <br />
          Ürünü kargo görevlisine iade edin.
          <br />
          Burada dikkat edilmesi gereken bir nokta: Hasar Tespit Tutanağını
          hazırlamadan önce ürünü teslim aldığınıza dair bir imza atmamış
          olmanız gerekmektedir.
          <br />
          Kargo görevlisi acelesi olduğunu ve hemen gitmesi gerektiğini söylese
          bile bunun sizin yasal hakkınız olduğunu unutmayın.
        </p>
      </div>
    </div>
  );
};

export default DeliveryAndInstallation;
