import React from "react";
import Home from "./pages/Home";
import Basket from "./pages/Basket";
import { Route, Routes } from "react-router-dom";
import Communication from "./pages/Communication";
import ProductDetail from "./pages/ProductDetail";
import AuthIndex from "./components/Auth/AuthIndex";
import PersonalDataProtectioLaw from "./components/PersonalDataProtectioLaw";
import Dashboard from "./components/Account/Dashboard";
import PageTransition from "./components/PageTransition";
import ScrollToTop from "./components/ScrollToTop";
import ProductList from "./components/ProductList";

export default function App() {
  return (
    <div>
      <PageTransition>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/sepetim" element={<Basket/>} />
          <Route path="/iletisim" element={<Communication/>} />
          <Route path="/:name" element={<ProductDetail/>} />
          <Route path="/üye-girisi" element={<AuthIndex/>} />
          <Route path="/hesabım" element={<Dashboard/>} />
          <Route path="/kategoriler" element={<ProductList/>} />
          <Route path="/kisisel-verilerin-korunması" element={<PersonalDataProtectioLaw/>} />
        </Routes>
      </PageTransition>
      <ScrollToTop />
    </div>
  );
}
