import React from "react";


export default function PersonalDataProtectionLaw() {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Kişisel Verilerin Korunmasına İlişkin Bilgilendirme</h1>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ. olarak kişisel verilerinizin 6698 sayılı Kişisel Verilerin Korunması Kanunu'na (“Kanun”) uygun olarak işlenerek, muhafaza edilmesine büyük önem veriyoruz. Müşterilerimizi kişisel verileri toplama, işleme, aktarma amacımız ve yöntemlerimiz ve buna bağlı olarak sizlerin Kanun'dan kaynaklanan haklarınızla ilgili bilgilendirmek isteriz.
      </p>

      <h2 className="text-xl font-semibold mt-6">1. Kişisel Verilerin Toplanmasına İlişkin Yöntemler</h2>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ. olarak, veri sorumlusu sıfatıyla, mevzuattan kaynaklanan yasal yükümlülüklerimiz çerçevesinde; markalarımızın hizmetlerinden faydalanabilmeniz, onayınız halinde kampanyalarımız hakkında sizleri bilgilendirmek, öneri ve şikayetlerinizi kayıt altına alabilmek, sizlere daha iyi hizmet standartları oluşturabilmek, NİS MOBİLYA SAN. TİC. LTD. ŞTİ. ticari ve iş stratejilerinin belirlenmesi ve uygulanması gibi amaçlarla kişisel verilerinizi sözlü, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik yöntemlerle toplamaktayız.
      </p>

      <h2 className="text-xl font-semibold mt-6">2. Kişisel Verilerin İşlenmesi ve İşleme Amaçları</h2>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ. olarak, veri sorumlusu sıfatı ile çağrı merkezlerimiz, yazılı iletişim kanallarımız, sosyal medya sayfalarımız, mobil iletişim kanalları, mağaza içi iletişim kanalları ve/veya bunlarla sınırlı olmamak üzere her türlü kanallar aracılığı ile; onayınız dahilinde elde ettiğimiz kişisel ve/veya özel nitelikli kişisel verileriniz tamamen veya kısmen elde edilebilir, kaydedilebilir, saklanabilir, depolanabilir, değiştirilebilir, güncellenebilir, periyodik olarak kontrol edilebilir, yeniden düzenlenebilir, sınıflandırılabilir, işlendikleri amaç için gerekli olan ya da ilgili kanunda öngörülen süre kadar muhafaza edilebilir, kanuni ya da hizmete bağlı fiili gereklilikler halinde NİS MOBİLYA SAN. TİC. LTD. ŞTİ.'nın birlikte çalıştığı özel-tüzel kişilerle ya da kanunen yükümlü olduğu kamu kurum ve kuruluşlarıyla ve/veya Türkiye'de veya yurt dışında mukim olan ilgili 3. kişi gerçek kişi/tüzel kişilerle paylaşılabilir/devredilebilir, kanuni ya da hizmete bağlı fiili gereklilikler halinde yurtdışına aktarılabilir.
      </p>

      <h2 className="text-xl font-semibold mt-6">3. Kişisel Verilerin Aktarılması</h2>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ., söz konusu kişisel verilerinizi sadece; açık rızanıza istinaden veya Kanun'da belirtilen güvenlik ve gizlilik esasları çerçevesinde yeterli önlemler alınmak kaydıyla yurt içinde ve gerekli güvenlik önlemlerinin alınması kaydıyla yurt dışında, Şirket faaliyetlerinin yürütülmesi, veri sahipleri ile müşterilerimiz arasındaki iş ilişkisinin sağlanması ve/veya bu amaçla görüşmeler yapılması, hizmetler, fırsat ve olanaklar sunulması ve hizmet kalitesinin artırılması amacıyla; grup şirketlerimiz, iş ortaklarımız, faaliyetlerimizin gereği anlaşmalı olduğumuz ve hizmet sunduğumuz müşteriler, tedarikçiler, denetim şirketleri veya yasal bir zorunluluk gereği bu verileri talep etmeye yetkili olan kamu kurum veya kuruluşları, bunlarla sınırlı olmamak üzere ilgili diğer otoriteler ile paylaşabilecektir.
      </p>

      <h2 className="text-xl font-semibold mt-6">4. Kişisel Veri Sahibinin KVK Kanunu'nun (“Kanun”) 11. Maddesinde Sayılan Hakları</h2>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ. ilgili kişilerin aşağıdaki taleplerine karşılık verecektir:
        <ul className="list-disc list-inside mt-2">
          <li>NİS MOBİLYA SAN. TİC. LTD. ŞTİ.'nın kendilerine ilişkin kişisel verileri işleyip işlemediğini ve hangi kişisel verileri işlediğini öğrenme,</li>
          <li>İşleme faaliyetinin amaçlarına ilişkin bilgi alma,</li>
          <li>NİS MOBİLYA SAN. TİC. LTD. ŞTİ.'nın yurt içinde veya yurt dışında kişisel verileri aktardığı üçüncü kişileri bilme,</li>
          <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
          <li>Kanun'a uygun olarak kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
          <li>Kişisel verilerin düzeltilmesi, silinmesi ya da yok edilmesi talebi halinde; yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
          <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve</li>
          <li>Kişisel verilerinin birer kopyasını alma.</li>
        </ul>
      </p>

      <h2 className="text-xl font-semibold mt-6">İletişim</h2>
      <p>
        Ünvan: NİS MOBİLYA SAN. TİC. LTD. ŞTİ.<br />
        Adres: Oymaağaç Mah. 5083 Sok.No: 3/E Kocasinan/ Kayseri<br />
        Telefon: 0546 469 28 66 - 0533 608 28 66<br />
        Mail: info@nismobilya.com<br />
        Vergi Dairesi: <br />
        Mersis No: 
      </p>

      <h2 className="text-xl font-semibold mt-6">Gizlilik ve Güvenlik</h2>
      <p>
        NİS MOBİLYA SAN. TİC. LTD. ŞTİ. Online Shop, müşterilerine daha iyi hizmet verebilmek amacıyla bazı kişisel bilgilerinizi (isim, yaş, ilgi alanlarınız, e-posta vb.) sizlerden talep etmektedir. NİS MOBİLYA SAN. TİC. LTD. ŞTİ. Online Shop sunucularında toplanan bu bilgiler, dönemsel kampanya çalışmaları, müşteri profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen e-postaların iletilmemesine yönelik müşteri "sınıflandırma" çalışmalarında sadece NİS MOBİLYA SAN. TİC. LTD. ŞTİ. Online Shop bünyesinde kullanılmaktadır. NİS MOBİLYA SAN. TİC. LTD. ŞTİ. Online Shop, üyelik formlarından topladığı bilgileri söz konusu üyenin haberi ya da aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta, faaliyet dışı hiçbir nedenle ticari amaçla kullanmamakta ve de satmamaktadır.
      </p>

      <h2 className="text-xl font-semibold mt-6">Çerez Kullanımı</h2>
      <p>
        Çerezler, sitemizi ziyaret ettiğinizde bilgisayarınız ya da mobil cihazınıza (akıllı telefon veya tablet gibi) kaydedilen küçük metin dosyaları ya da bilgilerdir.
      </p>
      <p>
        Çerezleri, Sitelerimizin daha kolay kullanılması ve sizin ilgi ve ihtiyaçlarınıza göre ayarlanması için kullanıyoruz. İnternet siteleri bu çerez dosyaları okuyup yazabiliyorlar ve bu sayede sizi tanıyabiliyor ve önemli bilgileri hatırlayabiliyorlar ki, internet sitesini sizin için daha kullanışlı ve size özel hale getirebilsinler.
      </p>
    </div>
  );
}
