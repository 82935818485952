import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function SiteMap({ category, subCategory, name }) {
  return (
    <div>
      <nav className="flex items-center text-gray-700 mt-4 mb-4 ml-6">
        <FaMapMarkerAlt className="text-gray-800 mr-2" />
        <span className="text-gray-800 text-xs font-display">Ana Sayfa</span>
        <span className="mx-2"></span>
        <a href="#" className="text-gray-800 hover:underline font-display text-xs ">
          {category}
        </a>
        <span className="mx-2"></span>
        <a href="#" className="text-gray-800 hover:underline font-display text-xs ">
          {subCategory}
        </a>
        <span className="mx-2"></span>
        <span className="text-gray-800 font-display text-xs ">{name}</span>
      </nav>
      <div className="w-full h-[1px] bg-gray-400 divide-y divide-double container mb-4 ml-6"></div>
    </div>
  );
}
