import React, { useState, useEffect } from "react";
import { Toaster, toast } from 'react-hot-toast';
import { auth } from '../../firebase'; // Adjust the path as needed
import {
  FaStar,
  FaWhatsapp,
  FaFacebookF,
  FaPinterestP,
  FaGooglePlusG,
  FaExclamationTriangle,
  FaHeart,
  FaListUl,
  FaBalanceScale,
  FaBell,
  FaTruck,
} from "react-icons/fa";
import { BsHandThumbsUp } from "react-icons/bs";
import { GoPencil } from "react-icons/go";
import { FaXTwitter } from "react-icons/fa6";

export default function ProductDetails({ product }) {
  const [rating, setRating] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check user authentication state
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const notify = () => {
    toast('Çalışmalarımız devam etmektedir. Lütfen daha sonra tekrar deneyiniz.', {
      icon: <FaExclamationTriangle />,
    });
  };

  const handleButtonClick = (path) => {
    if (user) {
      notify();
    } else {
      window.location.href = "/üye-girisi";
    }
  };

  return (
    <div>
      <Toaster />
      <div className="p-4">
        {/* Ürün Adı */}
        <h1 className="text-xl font-display font-semibold mb-2">
          {product.name}
        </h1>

        {/* Kategori Adı */}
        <p className="text-gray-800 text-md font-display mb-2">
          {product.category}
        </p>

        {/* Marka */}
        <p className="text-gray-800 mb-2 text-xs">
          Marka: <span className="text-xs font display">{product.brand}</span>
        </p>

        {/* Ürün Puanı */}
        <div className="flex items-center mb-4">
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              className={index < rating ? "text-yellow-500" : "text-gray-300"}
              onClick={() => handleRating(index + 1)}
            />
          ))}
        </div>

        {/* WhatsApp ile Sipariş Ver Butonu */}
        <a href="https://wa.me/+905454902866">
          <button className="flex items-center bg-green-500 text-white px-4 py-2 rounded mb-4 mt-16">
            <FaWhatsapp className="mr-2" /> WhatsApp ile Sipariş Ver
          </button>
        </a>

        {/* Fiyat Sorunuz */}
        <div className="border w-full bg-gray-200 p-4 mb-4 font-display">
          <p className="text-center">Fiyat Sorunuz</p>
        </div>

        {/* Tavsiye Et ve Yorum Yaz Butonları */}
        <div className="flex flex-row mb-4 gap-x-4">
          <div className="flex flex-row items-center bg-gray-200 border px-2 py-1">
            <BsHandThumbsUp />
            <button
              className="text-gray-800 px-4 py-1 font-display"
              onClick={() => handleButtonClick("/tavsiye-et")}
            >
              Tavsiye Et
            </button>
          </div>
          <div className="flex flex-row items-center bg-gray-200 border px-2 py-1">
            <GoPencil />
            <button
              className="text-gray-800 px-4 py-1 font-display"
              onClick={() => handleButtonClick("/yorum-yaz")}
            >
              Yorum Yaz
            </button>
          </div>
        </div>

        {/* Paylaş ve Ek Özellikler */}
        <div className="flex items-center mb-4 mt-20">
          {/* Paylaş */}
          <div className="flex items-center space-x-4">
            <p className="text-black font-semibold">Paylaş</p>
            <div className="flex space-x-2">
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://nismobilya.com">
                <div className="bg-blue-600 p-2 rounded-full cursor-pointer hover:bg-blue-700">
                  <FaFacebookF className="text-white" />
                </div>
              </a>
              <a href="https://twitter.com/intent/tweet?url=https://nismobilya.com&text=Check%20this%20out!">
                <div className="bg-blue-400 p-2 rounded-full cursor-pointer hover:bg-blue-500">
                  <FaXTwitter className="text-white" />
                </div>
              </a>
              <a href="https://pinterest.com/pin/create/button/?url=https://nismobilya.com&media=https://nismobilya.com/image.jpg&description=Check%20this%20out!">
                <div className="bg-red-500 p-2 rounded-full cursor-pointer hover:bg-red-600">
                  <FaPinterestP className="text-white" />
                </div>
              </a>
              <a href="/">
                <div className="bg-red-600 p-2 rounded-full cursor-pointer hover:bg-red-700">
                  <FaGooglePlusG className="text-white" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
