// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDU5nywOruW1Sb2VM5aiRkmsU-YJveBAO4",
  authDomain: "nis-home-web.firebaseapp.com",
  projectId: "nis-home-web",
  storageBucket: "nis-home-web.appspot.com",
  messagingSenderId: "960591197447",
  appId: "1:960591197447:web:291fa1dabef95c83d141a2",
  measurementId: "G-BFDNZQJZDM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
