export const products = [
  {
    id: "1",
    name: "Armoni Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "1",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Armoni.jpg",
    images: {
      thumbnails: ["/nis-foto/Armoni.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "2",
    name: "Anka Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Anka.jpg",
    images: {
      thumbnails: ["/nis-foto/Anka.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "3",
    name: "Hürrem Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/hurrem.jpg",
    images: {
      thumbnails: ["/nis-foto/hurrem.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "4",
    name: "Nizam Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Nizam.jpg",
    images: {
      thumbnails: ["/nis-foto/Nizam.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "5",
    name: "Ferman Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Ferman.jpg",
    images: {
      thumbnails: ["/nis-foto/Ferman.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "6",
    name: "Konak Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/konak.jpg",
    images: {
      thumbnails: ["/nis-foto/konak.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "7",
    name: "Alya Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Alya.jpg",
    images: {
      thumbnails: ["/nis-foto/Alya.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "8",
    name: "Sultan Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Sultan.jpg",
    images: {
      thumbnails: ["/nis-foto/Sultan.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "9",
    name: "Sissi Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/sisi.jpg",
    images: {
      thumbnails: ["/nis-foto/sisi.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "10",
    name: "Duru Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Duru.jpg",
    images: {
      thumbnails: ["/nis-foto/Duru.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "10",
    name: "Hünkar Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/hunkar.jpg",
    images: {
      thumbnails: ["/nis-foto/hunkar.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "11",
    name: "Milano Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Milano.jpg",
    images: {
      thumbnails: ["/nis-foto/Milano.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "12",
    name: "Azra Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Azra2.jpg",
    images: {
      thumbnails: ["/nis-foto/Azra2.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "13",
    name: "Divan Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Divan.jpg",
    images: {
      thumbnails: ["/nis-foto/Divan.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "14",
    name: "Yıldız Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Yildiz2.jpg",
    images: {
      thumbnails: ["/nis-foto/Yildiz2.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "15",
    name: "Asil Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Asil2.jpg",
    images: {
      thumbnails: ["/nis-foto/Asil2.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "16",
    name: "Şah Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Sah.jpg",
    images: {
      thumbnails: ["/nis-foto/Sah.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "17",
    name: "Dilber Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Dilber1.jpg",
    images: {
      thumbnails: ["/nis-foto/Dilber1.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "18",
    name: "Kaan Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Kaan.jpg",
    images: {
      thumbnails: ["/nis-foto/Kaan.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "19",
    name: "Kuğu Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Kugu.jpg",
    images: {
      thumbnails: ["/nis-foto/Kugu.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "20",
    name: "Tuğra Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Tugra.jpg",
    images: {
      thumbnails: ["/nis-foto/Tugra.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "21",
    name: "Joker Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Joker.jpg",
    images: {
      thumbnails: ["/nis-foto/Joker.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "22",
    name: "Ekol Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Ekol.jpg",
    images: {
      thumbnails: ["/nis-foto/Ekol.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "23",
    name: "Mihre Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Mihre.jpg",
    images: {
      thumbnails: ["/nis-foto/Mihre.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "24",
    name: "Han Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Han.jpg",
    images: {
      thumbnails: ["/nis-foto/Han.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "25",
    name: "Sahra Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Modern Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Sahra.jpg",
    images: {
      thumbnails: ["/nis-foto/Sahra.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "26",
    name: "Sissi Köşe Koltuk Takımı",
    category: "Köşe Takımları",
    subCategory: "Köşe Takımları",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/SisiKose.jpg",
    images: {
      thumbnails: ["/nis-foto/SisiKose.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "27",
    name: "Milano Köşe Koltuk Takımı",
    category: "Köşe Takımları",
    subCategory: "Köşe Takımları",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/MilanoKose.jpg",
    images: {
      thumbnails: ["/nis-foto/MilanoKose.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "28",
    name: "Konak Köşe Koltuk Takımı",
    category: "Köşe Takımları",
    subCategory: "Köşe Takımları",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Konak Kose.jpg",
    images: {
      thumbnails: ["/nis-foto/Konak Kose.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "29",
    name: "Sultan Köşe Koltuk Takımı",
    category: "Köşe Takımları",
    subCategory: "Köşe Takımları",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/SultanKose.jpg",
    images: {
      thumbnails: ["/nis-foto/SultanKose.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
  {
    id: "30",
    name: "Lukas Koltuk Takımı",
    category: "Oturma Grupları",
    subCategory: "Chester Koltuk Takımı",
    stock: "0",
    brand: "Nis Mobilya",
    img: "/nis-foto-kucuk/Lukas.jpg",
    images: {
      thumbnails: ["/nis-foto/Lukas.jpg"],
    },
    features: [
      "Yüksek kaliteli dikiş",
      "Yumuşak ve konforlu minderler",
      "Şık ve modern tasarım",
      "Kolay temizlenebilir kumaş",
      "Dayanıklı ahşap iskelet",
      "Kullanım kolaylığı için modüler yapısı",
    ],
  },
];

export default products;
