import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Firestore için gerekli importlar
import { useNavigate } from "react-router-dom"; // React Router'dan useNavigate kancasını import et
import {
  FaUser,
  FaBell,
  FaMapMarkerAlt,
  FaSyncAlt,
  FaShoppingCart,
  FaGift,
  FaStar,
  FaLifeRing,
} from "react-icons/fa";
import InformationBar from "../Navbar/InformationBar";
import Search from "../Navbar/Search";
import CategoriesNavbar from "../Navbar/CategoriesNavbar";
import Footer from "../Footer";
import ENewsletter from "../ENewsletter";

const AccountDashboard = () => {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const auth = getAuth();
  const db = getFirestore(); // Firestore veritabanını başlatma
  const navigate = useNavigate(); // useNavigate kancasını kullanarak yönlendirme

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);

        // Firestore'dan kullanıcı adını ve soyadını almak için:
        const userDoc = doc(db, "users", user.uid); // `users` koleksiyonunda kullanıcıyı bul
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(`${userData.name.trim()} ${userData.surname.trim()}`); // Firestore'dan alınan ad ve soyad
        } else {
          console.log("Belirtilen belge mevcut değil!");
        }
      } else {
        // Kullanıcı yoksa, giriş sayfasına yönlendir
        navigate("/üye-girisi");
      }
    });

    return () => unsubscribe();
  }, [auth, db, navigate]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate("/üye-girisi"); // Çıkış yaptıktan sonra giriş sayfasına yönlendir
      })
      .catch((error) => {
        console.error("Çıkış yapma hatası", error);
      });
  };

  if (!user) {
    return <p>Giriş yapmadınız. Bu sayfaya erişmek için lütfen giriş yapın.</p>;
  }

  const items = [
    { icon: <FaLifeRing />, label: "Destek Taleplerim" },
    { icon: <FaBell />, label: "Siparişlerim" },
    { icon: <FaUser />, label: "Üyelik Bilgilerim" },
    { icon: <FaMapMarkerAlt />, label: "Adres Defterim" },
    { icon: <FaGift />, label: "Hediye Çeklerim" },
    { icon: <FaStar />, label: "Para Puanlarım" },
    { icon: <FaSyncAlt />, label: "İade Taleplerim" },
    { icon: <FaShoppingCart />, label: "Alışveriş Sepetim" },
  ];

  return (
    <div>
      <div className="hidden md:block">
        <InformationBar />
        <Search />
        <CategoriesNavbar />
      </div>
      <div className="p-6 flex flex-col lg:flex-row">
        <aside className="bg-gray-100 p-4 w-full lg:w-1/4">
          <ul>
            <li className="py-2 hover:bg-gray-200">Hesap Ayarlarım</li>
            <li className="py-2 hover:bg-gray-200">Siparişlerim</li>
            <li className="py-2 hover:bg-gray-200">İade Taleplerim</li>
            <li className="py-2 hover:bg-gray-200">Alışveriş Sepetim</li>
            <li className="py-2 hover:bg-gray-200">Favorilerim</li>
            <li className="py-2 hover:bg-gray-200">Yorumlarım</li>
            <li className="py-2 hover:bg-gray-200">İstek Listelerim</li>
            <li className="py-2 hover:bg-gray-200">Fiyat Alarm Listem</li>
            <li className="py-2 hover:bg-gray-200">Stok Alarm Listem</li>
            <li className="py-2 hover:bg-gray-200">Sözleşmelerim</li>
            <li className="py-2 hover:bg-gray-200">Para Puan / Hediye Çeki</li>
            <li className="py-2 hover:bg-gray-200">Destek Taleplerim</li>
            <li className="py-2 hover:bg-gray-200">Havale Bildirim Formu</li>
            <li
              onClick={handleSignOut}
              className="py-2 hover:bg-gray-200 cursor-pointer"
            >
              Güvenli Çıkış
            </li>
          </ul>
        </aside>

        <main className="flex-grow p-6">
          <h2 className="text-2xl mb-4">MERHABA {userName || "Kullanıcı"}</h2>
          <p className="mb-8">
            "Hesabım" sayfasından siparişlerinizi ve arıza/iade/değişim
            işlemlerinizi takip edebilir, kazandığınız hediye çeki ve puanları
            görüntüleyebilir, üyelik bilgisi güncelleme, şifre ve adres
            değişikliği gibi hesap ayarlarınızı kolayca yapabilirsiniz.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {items.map((item, index) => (
              <div
                key={index}
                className="bg-gray-200 p-6 rounded-lg flex flex-col items-center justify-center text-gray-600 hover:bg-black hover:text-white transition duration-200 cursor-pointer"
              >
                <div className="text-4xl mb-4">{item.icon}</div>
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </main>
      </div>
      <ENewsletter />
      <Footer />
    </div>
  );
};

export default AccountDashboard;
