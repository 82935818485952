import React from "react";

export default function GoogleMaps() {
  return (
    <div className="mt-6">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d777.6213052917693!2d35.432074710650795!3d38.77550949399789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1724411980248!5m2!1str!2str"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
}
