import React from "react";
import InformationBar from '../components/Navbar/InformationBar'
import Search from '../components/Navbar/Search'
import CategoriesNavbar from '../components/Navbar/CategoriesNavbar'
import ResponsiveNavbar from '../components/Navbar/ResponsiveNavbar'
import ContactForm from "../components/Communication/ContactForm";
import Address from "../components/Communication/Address";
import GoogleMaps from "../components/Communication/GoogleMaps";
import Footer from '../components/Footer'

const Communication = () => {
  return (
    <div>
      <InformationBar />
      <div className="hidden md:block">
        <Search />
      </div>
      <div className="hidden md:block">
        {" "}
        <CategoriesNavbar />
      </div>
      <div className="md:hidden">
        <ResponsiveNavbar />
      </div>
      <div className="p-6">
        {/* İletişim ve İletişim Formu Bölümü */}
        <div className="flex flex-col md:flex-row gap-6">
          {/* İletişim Bölümü */}
          <Address />

          {/* İletişim Formu Bölümü */}
          <ContactForm />
        </div>

        {/* Harita Bölümü */}
        <GoogleMaps />
      </div>
      <Footer />
    </div>
  );
};

export default Communication;
